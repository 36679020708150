import { Component, OnInit } from '@angular/core';
import { WebserverService } from 'src/app/_service/webserver.service';
import { GeneralData } from 'src/app/_class/general-data';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {
  private generalData: GeneralData;

  constructor(
    private webserverService: WebserverService
  ) { }


  ngOnInit() {
    this.webserverService.get_generalData().subscribe(
      (value) => {
        this.generalData = value;
    });
  }
}
