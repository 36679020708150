export class GeneralData {
    idSezione: string;
    showBottomBar: boolean;
    showTopBar: boolean;
    menuBackLink: string;
    nomeSezione: string;
    nomeCategoria: string;
    nextNomeCategoria: string;
    prevNomeCategoria: string;
    nextIdCategoria: number;
    prevIdCategoria: number;
    logo: string;
    testoHome: string;
    testoServizio: string;
}
