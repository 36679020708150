import { Component, OnInit  } from '@angular/core';
import {
  Router,
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart
} from '@angular/router';
import { WebserverService } from './_service/webserver.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'salefino';
  home: boolean;
  loading = false;

  constructor(
    private router: Router,
    private webserverService: WebserverService
  ) {
    this.router.events.subscribe((url: any) => (
      this.webserverService.updateUrlParams(url.url)
     ));

    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
//          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });

    this.webserverService.get_generalData().subscribe((value) => {
      this.home = value.idSezione === '0' ? true : false;
      this.loading = false;
    });
  }

  ngOnInit() {


  }


}
