import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './_pages/home/home.component';
import { CategorieComponent } from './_pages/categorie/categorie.component';
import { ElencoComponent } from './_pages/elenco/elenco.component';
import { MenuGiornoComponent } from './_pages/menu-giorno/menu-giorno.component';
import { Page404Component } from './_pages/page404/page404.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'menugiorno/:idSezione',
    component: MenuGiornoComponent
  },
  {
    path: 'categorie/:idSezione',
    component: CategorieComponent
  },
  {
    path: 'elenco/:idSezione/:idCategoria',
    component: ElencoComponent
  },
  {
    path: 'page404',
    component: Page404Component
  },
  {
    path: '**',
    component: Page404Component
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
