import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WebserverService } from 'src/app/_service/webserver.service';
import { Elemento } from 'src/app/_class/elemento';
import { GeneralData } from 'src/app/_class/general-data';

@Component({
  selector: 'app-elenco',
  templateUrl: './elenco.component.html',
  styleUrls: ['./elenco.component.scss']
})
export class ElencoComponent implements OnInit {
  arrayData: Elemento[];
  idSezione: number;
  idCategoria: number;
  private generalData: GeneralData;


  constructor(
    private webserverService: WebserverService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    route.params.subscribe(val => {
      this.idSezione = +this.route.snapshot.paramMap.get('idSezione');
      this.idCategoria = +this.route.snapshot.paramMap.get('idCategoria');

      this.webserverService.getElenco(this.idSezione, this.idCategoria)
      .subscribe(data => this.updateData(data));
    });
  }


  updateData(data: any): void {
    if (data.fail === 1) {
      this.router.navigate(['/page404']);
    }
    this.arrayData = data;
  }

  ngOnInit() {
    this.webserverService.get_generalData().subscribe(
      (value) => {
        this.generalData = value;
    });
  }

}
