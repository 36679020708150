import { Component, OnInit } from '@angular/core';
import { WebserverService } from 'src/app/_service/webserver.service';
import { GeneralData } from 'src/app/_class/general-data';

@Component({
  selector: 'app-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.scss']
})
export class BottomBarComponent implements OnInit {
  private generalData: GeneralData;

  constructor(
    private webserverService: WebserverService
  ) { }


  ngOnInit() {
    this.webserverService.get_generalData().subscribe(
      (value) => {
        this.generalData = value;
    });
  }
}
