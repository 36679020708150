import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bottom-credits',
  templateUrl: './bottom-credits.component.html',
  styleUrls: ['./bottom-credits.component.scss']
})
export class BottomCreditsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
