import { Component, OnInit } from '@angular/core';
import { VoceHome } from 'src/app/_class/voce-home';
import { ActivatedRoute, Router } from '@angular/router';
import { WebserverService } from 'src/app/_service/webserver.service';

@Component({
  selector: 'app-categorie',
  templateUrl: './categorie.component.html',
  styleUrls: ['./categorie.component.scss']
})
export class CategorieComponent implements OnInit {
  arrayData: VoceHome[];
  idSezione: number;

  constructor(
    private webserverService: WebserverService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  updateData(data: any): void {
    if (data.fail === 1) {
      this.router.navigate(['/page404']);
    }
    this.arrayData = data;
  }

  ngOnInit() {
    this.idSezione = +this.route.snapshot.paramMap.get('idSezione');

    this.webserverService.getCategorie(this.idSezione)
    .subscribe(data => this.updateData(data));
  }

}
