import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopBarComponent } from './_components/top-bar/top-bar.component';
import { BottomBarComponent } from './_components/bottom-bar/bottom-bar.component';
import { HomeComponent } from './_pages/home/home.component';
import { CategorieComponent } from './_pages/categorie/categorie.component';
import { ElencoComponent } from './_pages/elenco/elenco.component';
import { MenuGiornoComponent } from './_pages/menu-giorno/menu-giorno.component';
import { BottomCreditsComponent } from './_components/bottom-credits/bottom-credits.component';
import { Page404Component } from './_pages/page404/page404.component';

@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    BottomBarComponent,
    HomeComponent,
    CategorieComponent,
    ElencoComponent,
    MenuGiornoComponent,
    BottomCreditsComponent,
    Page404Component
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFontAwesomeModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
