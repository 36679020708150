import { Component, OnInit } from '@angular/core';
import { VoceHome } from 'src/app/_class/voce-home';
import { WebserverService } from 'src/app/_service/webserver.service';
import { GeneralData } from 'src/app/_class/general-data';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  arrayData: VoceHome[];
  private generalData: GeneralData;

  constructor(
    private webserverService: WebserverService,
  ) { }

  updateData(data: VoceHome[]): void {
    this.arrayData = data;
  }

  ngOnInit() {
    this.webserverService.getHome()
    .subscribe(data => this.updateData(data));

    this.webserverService.get_generalData().subscribe(
      (value) => {
        this.generalData = value;
    });
  }
}
