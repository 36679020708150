import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { VoceHome } from '../_class/voce-home';
import { Elemento } from '../_class/elemento';
import { GeneralData } from '../_class/general-data';

export const baseUrl = 'https://salefino.dreamsoftware.cloud/webserver.php';

@Injectable({
  providedIn: 'root'
})
export class WebserverService {
  private generalData: GeneralData;
  private observableGeneralData: BehaviorSubject<GeneralData>;
  private globalIdSezione = '';
  private globalIdCategoria = '';

  constructor(
    private http: HttpClient
  ) {
    this.generalData = new GeneralData();
    this.observableGeneralData = new BehaviorSubject<GeneralData>(this.generalData);
  }

  get_generalData(): Observable<GeneralData> {
    return this.observableGeneralData.asObservable();
  }


  updateUrlParams(url: string) {
    if (url !== undefined) {
      const arrayUrl = url.split('/');
      const sezione = arrayUrl[1];
      let idSezione = '0';
      if (sezione !== '') {
        this.generalData.showBottomBar = true;
        idSezione = arrayUrl[2];
      } else {
        this.generalData.showBottomBar = false;
      }
      this.generalData.idSezione = idSezione;

      let idCategoria = '0';
      if ((arrayUrl.length === 4) && (sezione === 'elenco')) {
        idCategoria = arrayUrl[3];
        this.generalData.menuBackLink = '/categorie/' + arrayUrl[2];
        this.generalData.showTopBar = true;
      } else {
        this.generalData.menuBackLink = '';
        this.generalData.showTopBar = false;
      }
//      console.log(this.generalData);
//      console.log(idSezione + ' - ' + idCategoria);

      if ((this.globalIdSezione !== idSezione) || (this.globalIdCategoria !== idCategoria))  {
        this.globalIdSezione = idSezione;
        this.globalIdCategoria = idCategoria;
        this.getGeneralData(Number(idSezione), Number(idCategoria))
        .subscribe(data => this.observableGeneralData.next(Object.assign({}, this.generalData, data)));
      }
    }
  }

  getGeneralData(idSezione: number, idCategoria: number): Observable<any> {
    const url = `${baseUrl}?action=getGeneralData&idSezione=` + idSezione + `&idCategoria=` + idCategoria;
    return this.http.get<any>(url)
    .pipe(
      catchError(this.errorhandler)
    );
  }


  getHome(): Observable<VoceHome[]> {
    const url = `${baseUrl}?action=getHome`;
    return this.http.get<VoceHome[]>(url)
    .pipe(
      catchError(this.errorhandler)
    );
  }

  getCategorie(idSezione: number): Observable<VoceHome[]> {
    const url = `${baseUrl}?action=getCategorie&idSezione=` + idSezione;
    return this.http.get<VoceHome[]>(url)
    .pipe(
      catchError(this.errorhandler)
    );
  }

  getElenco(idSezione: number, idCategoria: number): Observable<Elemento[]> {
    const url = `${baseUrl}?action=getElenco&idSezione=` + idSezione + `&idCategoria=` + idCategoria;
    return this.http.get<Elemento[]>(url)
    .pipe(
      catchError(this.errorhandler)
    );
  }

  getMenuGiorno(): Observable<Elemento[]> {
    const url = `${baseUrl}?action=getMenuGiorno`;
    return this.http.get<Elemento[]>(url)
    .pipe(
      catchError(this.errorhandler)
    );
  }

  errorhandler(error: any) {
    let msg: string;
    if (error instanceof HttpErrorResponse)  {
      if (error.status === 0) {
        msg = 'Applicazione offline';
      } else {
        msg = 'Si è verificato un errore: ${error.error.msg} (server status code ${error.status})';
      }
      return throwError(msg);
    }
    return throwError('Si è verificato un errore di tipo: ${error.message}');
  }
}
