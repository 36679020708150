import { Component, OnInit } from '@angular/core';
import { WebserverService } from 'src/app/_service/webserver.service';
import { Elemento } from 'src/app/_class/elemento';
import { GeneralData } from 'src/app/_class/general-data';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-menu-giorno',
  templateUrl: './menu-giorno.component.html',
  styleUrls: ['./menu-giorno.component.scss']
})
export class MenuGiornoComponent implements OnInit {
  arrayData: Elemento[];
  private generalData: GeneralData;

  constructor(
    private webserverService: WebserverService,
    private route: ActivatedRoute
  ) {
    route.params.subscribe(val => {

      this.webserverService.getMenuGiorno()
      .subscribe(data => this.updateData(data));
    });
  }

  updateData(data: Elemento[]): void {
    this.arrayData = data;
  }

  ngOnInit() {
    this.webserverService.get_generalData().subscribe(
      (value) => {
        this.generalData = value;
    });
  }

}
